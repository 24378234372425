import React, { useEffect, useState } from "react";
import AccountLayout from "./index";
import { useMyContext } from "../context";
// import back from "../../assets/images/header/icon/back.svg";
import { UpdateUserProfileData } from "../../utility/Apis";
import Form from "react-bootstrap/Form";
import { Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function PersonalInfo() {
  const { windowWidth, userDetails, setUserDetails } = useMyContext();
  const navigate = useNavigate();
  const [userDetailState, setuserDetailState] = useState(null);
  // const LockValue = localStorage.getItem("isLock") === "true" || false;
  // const [islock, setisLock] = useState(LockValue);

  useEffect(() => {
    if (userDetails?.id) {
      setuserDetailState(userDetails);
    }
  }, [userDetails?.id, userDetails]);

  //country name array
  const countryname = [
    { name: "India", value: "india" },
    { name: "Afghanistan", value: "afghanistan" },
    { name: "Albania", value: "albania" },
    { name: "Algeria", value: "algeria" },
    { name: "Andorra", value: "andorra" },
    { name: "Angola", value: "angola" },
    { name: "Antigua and Barbuda", value: "antigua-and-barbuda" },
    { name: "Argentina", value: "argentina" },
    { name: "Armenia", value: "armenia" },
    { name: "Australia", value: "australia" },
    { name: "Austria", value: "austria" },
    // Add more countries as needed
  ];

  // //Dates array
  // const date = Array.from({ length: 31 }, (_, i) => ({
  //   value: (i + 1).toString(),
  // }));

  // // months name
  // const months = [
  //   { name: "January", value: "1" },
  //   { name: "February", value: "2" },
  //   { name: "March", value: "3" },
  //   { name: "April", value: "4" },
  //   { name: "May", value: "5" },
  //   { name: "June", value: "6" },
  //   { name: "July", value: "7" },
  //   { name: "August", value: "8" },
  //   { name: "September", value: "9" },
  //   { name: "October", value: "10" },
  //   { name: "November", value: "11" },
  //   { name: "December", value: "12" },
  // ];

  // // years from 1930 to 2023
  // const years = [];
  // for (let year = 1930; year <= 2023; year++) {
  //   years.push({ name: year.toString(), value: year.toString() });
  // }

  const [isEditing, setisEditing] = useState({
    firstName: false,
    lastName: false,
    dob: false,
    mobile: false,
    country: false,
    city: false,
    address: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(" name, value ========---->>", { name, value });
    setuserDetailState({
      ...userDetailState,
      [name]: value,
    });
  };

  const handleEditClick = (fieldName) => {
    setisEditing({ ...isEditing, [fieldName]: true });
    // setuserDetailState(userDetailState?.fieldName);
  };
  // console.log("userDetailState=============", userDetailState);
  const handleSaveClick = (fieldName) => {
    setisEditing({ ...isEditing, [fieldName]: false });
  };

  const handleCancilClick = (fieldName) => {
    // setuserDetailState(userDetailState?.firstName);
    setisEditing({ ...isEditing, [fieldName]: false });
  };

  // ================================Api call here =============================
  const handleUpdateForm = async (key) => {
    const value = userDetailState[key];
    // console.log("key , value================>", `key${key}: ${value}`);
    if (userDetailState[key] !== userDetails[key]) {
      const res = await UpdateUserProfileData({ [key]: value });
      // console.log("res=====================>", res);
      if (res?.message === "Success") {
        // setUserDetails(res?.user);
        setUserDetails({
          ...userDetails,
          [key]: userDetailState[key],
        });
      }
      // if (res?.isLock === true) {
      //   setisLock(true);
      //   localStorage.setItem("isLock", "true");
      // }
    }
  };

  const handleGoBack = () => {
    // window.history.back();
    navigate("/view-all/false/lobby/lobby");;
  };
  return (
    <AccountLayout>
      {windowWidth <= 991 && (
        <div className="page-title">
          {/* <div className="back" onClick={handleGoBack}>
            <img src={back} alt="back" />{" "}
            <span text_key="PAYMENTS__BACK">BACK</span>
          </div> */}
          <h3 className="page-name" text_key="PROFILE_ACCOUNT__PERSONAL_INFO">
            PERSONAL INFO
          </h3>
          <Clear className="close-page" onClick={handleGoBack} />
        </div>
      )}

      <div className="personal-info-content">
        <div className="nav">
          <div className="rect balance">
            <div className="wallet-box-content">
              <span className="material-icons">wallet</span>
              <span text_key="PROFILE_ACCOUNT__BALANCE">Balance</span>
            </div>
            <div>
              {userDetails?.cryptoWallet?.USD ? (
                <span className="amount">
                  {userDetails &&
                    Number(userDetails?.cryptoWallet?.USD)?.toFixed(2)}
                </span>
              ) : (
                <span className="amount">0.00</span>
              )}

              <span className="currency-str">GC</span>
            </div>
          </div>
          {/* <!-- <div className='rect points'>
                  <div>
                      <span className='material-icons'>grade</span>
                      <span text_key='PROFILE_ACCOUNT__POINTS'></span>
                  </div>

                  <div>
                      <span className='amount'></span>
                      <span className='currency-str'></span>
                  </div>
              </div> --> */}
          {/* <span
            className="rect btn deposit not-webshop"
            onClick={() => {
              "deposit");
            }}
          >
            <span className="material-icons">payment</span>
            <span text_key="PROFILE_ACCOUNT__DEPOSIT">Deposit</span>
          </span> */}
        </div>
        <div className="content">
          <div className="half">
            <h2 text_key="PROFILE__USER_PROFILE">User Profile</h2>
            <ul className="fields">
              <li>
                <div text_key="PROFILE_ACCOUNT__USER_ID">User ID</div>
                <div className="info" field="ID">
                  {userDetails?.id || ""}
                </div>
                <span className="material-icons locked">lock_outline</span>
              </li>
              <li>
                <div text_key="PROFILE_ACCOUNT__USERNAME">Username</div>
                <div className="info" field="username">
                  {userDetails?.userName || ""}
                </div>
                <span className="material-icons locked">lock_outline</span>
              </li>
              <li className="password">
                <div text_key="PROFILE_ACCOUNT__PASSWORD">Password</div>
                <div className="info" field="password">
                  ************
                </div>
                <span className="material-icons editable changepass">edit</span>
              </li>
              <li>
                <div text_key="PROFILE_ACCOUNT__FIRST_NAME">First Name</div>
                {isEditing.firstName ? (
                  <>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={userDetailState?.firstName}
                      onChange={handleChange}
                      style={{
                        display: "block",
                        color: "white",
                        marginLeft: "10px",
                        width: "80%",
                        background: "#1f1f27",
                      }}
                    />
                    <span
                      className="material-icons "
                      onClick={() => {
                        handleSaveClick("firstName");
                        handleUpdateForm("firstName");
                      }}
                      style={{ color: "green" }}
                    >
                      done
                    </span>
                    <span
                      className="material-icons "
                      onClick={() => handleCancilClick("firstName")}
                    >
                      close
                    </span>
                  </>
                ) : (
                  <>
                    <div className="info" field="firstname">
                      {userDetails?.firstName}
                    </div>

                    {/* {islock ? (
                      <span className="material-icons locked">
                        lock_outline
                      </span>
                    ) : ( */}
                    <span
                      className="material-icons editable"
                      onClick={() => handleEditClick("firstName")}
                    >
                      edit
                    </span>
                    {/* )} */}
                  </>
                )}
              </li>
              <li>
                <div text_key="PROFILE_ACCOUNT__LAST_NAME">Last Name</div>
                {isEditing.lastName ? (
                  <>
                    <Form.Control
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      value={userDetailState?.lastName}
                      style={{
                        display: "block",
                        color: "white",
                        marginLeft: "10px",
                        width: "80%",
                        background: "#1f1f27",
                      }}
                    />
                    <span
                      className="material-icons "
                      onClick={() => {
                        handleSaveClick("lastName");
                        handleUpdateForm("lastName");
                      }}
                      style={{ color: "green" }}
                    >
                      done
                    </span>
                    <span
                      className="material-icons "
                      onClick={() => handleCancilClick("lastName")}
                    >
                      close
                    </span>
                  </>
                ) : (
                  <>
                    <div className="info" field="firstname">
                      {userDetails?.lastName}
                    </div>
                    <span
                      className="material-icons editable"
                      onClick={() => handleEditClick("lastName")}
                    >
                      edit
                    </span>
                  </>
                )}
              </li>
              <li>
                <div text_key="PROFILE_ACCOUNT__EMAIL">Email</div>
                <div className="info" field="email">
                  {userDetails?.email || ""}
                </div>
                <span className="material-icons locked">lock_outline</span>
                <input type="email" name="email" />
                <span className="material-icons save">done</span>
                <span className="material-icons cancel">close</span>
              </li>
            </ul>
          </div>
          <div className="half">
            <h2 text_key="PROFILE__PERSONAL_INFO">Personal Info</h2>
            <ul className="fields">
              {/* <li>
                <div text_key="PROFILE_ACCOUNT__BIRTHDATE">Birth Date</div>
                {isEditing.dob ? (
                  <>
                    <select
                      name="day"
                      // required
                      onChange={handleChange}
                      style={{
                        display: "block",
                        color: "black",
                        marginLeft: "10px",
                      }}
                    >
                      <option value="">DD</option>
                      {date?.map((value) => (
                        <option value={value?.value}>{value?.value}</option>
                      ))}
                    </select>
                    <select
                      name="month"
                      // required
                      onChange={handleChange}
                      style={{
                        display: "block",
                        color: "black",
                        marginLeft: "10px",
                      }}
                    >
                      <option value="">MM</option>

                      {months?.map((value) => (
                        <option value={value?.value}>{value?.name}</option>
                      ))}
                    </select>
                    <select
                      name="year"
                      // required
                      onChange={handleChange}
                      style={{
                        display: "block",
                        color: "black",
                        marginLeft: "10px",
                      }}
                    >
                      <option value="">YYYY</option>
                      {years?.map((value) => (
                        <option value={value?.value}>{value?.name}</option>
                      ))}
                    </select>
                    <span
                      className="material-icons "
                      onClick={() => {
                        handleSaveClick("dob");
                        handleUpdateForm("dob");
                      }}
                    >
                      done
                    </span>
                    <span
                      className="material-icons "
                      onClick={() => handleCancilClick("dob")}
                    >
                      close
                    </span>
                  </>
                ) : (
                  <>
                    <div className="info" field="birthdate">
                      {userDetails?.dob || ""}
                    </div>
                    <span
                      className="material-icons editable"
                      onClick={() => handleEditClick("dob")}
                    >
                      edit
                    </span>
                  </>
                )}
              </li> */}
              <li>
                <div text_key="PROFILE_ACCOUNT__BIRTHDATE">Birth Date</div>
                {isEditing.dob ? (
                  <>
                    <Form.Control
                      type="date"
                      name="dob"
                      value={userDetailState?.dob}
                      onChange={handleChange}
                      style={{
                        display: "block",
                        color: "white",
                        width: "80%",
                        marginLeft: "10px",
                        background: "#1f1f27",
                      }}
                    />
                    <span
                      className="material-icons "
                      onClick={() => {
                        handleSaveClick("dob");
                        handleUpdateForm("dob");
                      }}
                      style={{ color: "green" }}
                    >
                      done
                    </span>
                    <span
                      className="material-icons "
                      onClick={() => handleCancilClick("dob")}
                    >
                      close
                    </span>
                  </>
                ) : (
                  <>
                    <div className="info" field="birthdate">
                      {userDetails?.dob || ""}
                    </div>
                    <span
                      className="material-icons editable"
                      onClick={() => handleEditClick("dob")}
                    >
                      edit
                    </span>
                  </>
                )}
              </li>
              <li>
                <div text_key="PROFILE_ACCOUNT__MOBILE_NUMBER">
                  Mobile Number
                </div>
                {isEditing.mobile ? (
                  <>
                    <Form.Control
                      type="text"
                      name="mobile"
                      value={userDetailState?.mobile}
                      onChange={handleChange}
                      style={{
                        display: "block",
                        color: "white",
                        width: "80%",
                        marginLeft: "10px",
                        background: "#1f1f27",
                      }}
                    />
                    <span
                      className="material-icons "
                      onClick={() => {
                        handleSaveClick("mobile");
                        handleUpdateForm("mobile");
                      }}
                      style={{ color: "green" }}
                    >
                      done
                    </span>
                    <span
                      className="material-icons "
                      onClick={() => handleCancilClick("mobile")}
                    >
                      close
                    </span>
                  </>
                ) : (
                  <>
                    <div className="info" field="mobile">
                      {userDetails?.mobile}
                    </div>
                    <span
                      className="material-icons editable"
                      onClick={() => handleEditClick("mobile")}
                    >
                      edit
                    </span>
                  </>
                )}
              </li>
              <li>
                <div text_key="PROFILE_ACCOUNT__COUNTRY">Country</div>
                {isEditing.country ? (
                  <>
                    <Form.Select
                      type="text"
                      name="country"
                      onChange={handleChange}
                      style={{
                        display: "block",
                        color: "white",
                        width: "70%",
                        marginLeft: "10px",
                        background: "#1f1f27",
                      }}
                    >
                      <option value="">Select country</option>
                      {countryname?.map((country) => (
                        <option value={country?.value}>{country?.name}</option>
                      ))}
                    </Form.Select>

                    <span
                      className="material-icons"
                      onClick={() => {
                        handleSaveClick("country");
                        handleUpdateForm("country");
                      }}
                      style={{ color: "green" }}
                    >
                      done
                    </span>
                    <span
                      className="material-icons "
                      onClick={() => handleCancilClick("country")}
                    >
                      close
                    </span>
                  </>
                ) : (
                  <>
                    <div className="info" field="country">
                      {userDetails?.country}
                    </div>
                    <span
                      className="material-icons editable"
                      onClick={() => handleEditClick("country")}
                    >
                      edit
                    </span>
                  </>
                )}
              </li>
              <li>
                <div text_key="PROFILE_ACCOUNT__CITY">City</div>
                {isEditing.city ? (
                  <>
                    <Form.Control
                      type="text"
                      name="city"
                      value={userDetailState?.city}
                      onChange={handleChange}
                      style={{
                        display: "block",
                        color: "white",
                        width: "80%",
                        marginLeft: "10px",
                        background: "#1f1f27",
                      }}
                    />
                    <span
                      className="material-icons "
                      onClick={() => {
                        handleSaveClick("city");
                        handleUpdateForm("city");
                      }}
                      style={{ color: "green" }}
                    >
                      done
                    </span>
                    <span
                      className="material-icons "
                      onClick={() => handleCancilClick("city")}
                    >
                      close
                    </span>
                  </>
                ) : (
                  <>
                    <div className="info" field="city">
                      {userDetails?.city}
                    </div>
                    <span
                      className="material-icons editable"
                      onClick={() => handleEditClick("city")}
                    >
                      edit
                    </span>
                  </>
                )}
              </li>
              <li>
                <div text_key="PROFILE_ACCOUNT__ADDRESS">Address</div>
                {isEditing.address ? (
                  <>
                    <Form.Control
                      type="text"
                      name="address"
                      value={userDetailState?.address}
                      onChange={handleChange}
                      style={{
                        display: "block",
                        width: "80%",
                        color: "white",
                        marginLeft: "10px",
                        background: "#1f1f27",
                      }}
                    />
                    <span
                      className="material-icons "
                      onClick={() => {
                        handleSaveClick("address");
                        handleUpdateForm("address");
                      }}
                      style={{ color: "green" }}
                    >
                      done
                    </span>
                    <span
                      className="material-icons "
                      onClick={() => handleCancilClick("address")}
                    >
                      close
                    </span>
                  </>
                ) : (
                  <>
                    <div className="info" field="address">
                      {userDetails?.address || ""}
                    </div>
                    <span
                      className="material-icons editable"
                      onClick={() => handleEditClick("address")}
                    >
                      edit
                    </span>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
        <p className="err-text"></p>
      </div>
    </AccountLayout>
  );
}

export default PersonalInfo;
