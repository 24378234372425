import React, { useEffect, useState } from "react";
import Header from "../header";
// import Footer from "../footer";
import Sidebar from "../sidebar";
import "./layout.css";
import { Modal } from "react-bootstrap";
import LoginPopup from "../modals/loginPopup";
import SignupPopup from "../modals/signupPopup";
import ForgotPopup from "../modals/forgotPopup";
import { useMyContext } from "../context";
import TransectionPopup from "../modals/transectionPopup";
// import MenuNav from "../menubar/bottomNav";
import { useLocation } from "react-router-dom";
import ResetPopup from "../modals/resetPopup";
// import ResetPopup from "../modals/resetPopup";

function Layout({ children, handleShowTransection, headerGamePopup }) {
  const {
    // windowWidth,
    showLogin,
    showSignup,
    showForgot,
    showReset,
    forLogin,
    transection,
    activeTab,
    setActiveTab,
    handleLoginClose,
    handleLoginShow,
    handleSignupClose,
    handleSignupShow,
    handleForgotClose,
    handleForgotShow,
    handleForLogin,
    handleHideTransection,
    handleResetShow,
    handleResetClose,
  } = useMyContext();
  const dataLocation = useLocation() || "";
  const [resetToken, stateResetToken] = useState("");
  let spltedStr = dataLocation?.search?.split("=");
  let urlToken = spltedStr[1];
  useEffect(() => {
    if (spltedStr && spltedStr[0]?.split("-")?.includes("token")) {
      stateResetToken(urlToken);
      handleResetShow();
    }
    // eslint-disable-next-line
  }, [urlToken]);

  useEffect(() => {
    localStorage.setItem("loginState", JSON.stringify(forLogin));
  }, [forLogin]);

  // const playAudio = () => {
  //   if (!isPlay) {
  //     audioRef.loop = true;
  //     console.log("audio", audioRef);
  //     setIsPlay(true);
  //     audioRef.play().catch((error) => {
  //       console.error("Audio playback failed:", error);
  //     });
  //   }
  // };
  // playAudio();
  // useEffect(() => {
  //   if (!isPlay) {
  //   }
  // }, [isPlay]);

  return (
    <div className="wrapper">
      <Header
        handleShowTransection={handleShowTransection}
        headerGamePopup={headerGamePopup}
      />

      <Sidebar />
      <main
        className="middle"
        // style={{ paddingBottom: "75px" }}
      >
        {children}
      </main>
      {/* {windowWidth <= 991 && <MenuNav />} */}
      {/* <Footer /> */}

      <Modal
        show={showLogin || showSignup}
        onHide={handleLoginClose}
        centered
        className="jackbit-modal"
      >
        <Modal.Body>
          {showLogin ? (
            <LoginPopup
              handleLoginClose={handleLoginClose}
              handleLoginShow={handleLoginShow}
              handleSignupShow={handleSignupShow}
              handleForgotShow={handleForgotShow}
              handleForLogin={handleForLogin}
            />
          ) : (
            <SignupPopup
              handleSignupClose={handleSignupClose}
              handleLoginShow={handleLoginShow}
              handleSignupShow={handleSignupShow}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={showSignup}
        onHide={handleSignupClose}
        // centered
        className="jackbit-modal"
      >
        <Modal.Body>
          <SignupPopup
            handleSignupClose={handleSignupClose}
            handleLoginShow={handleLoginShow}
            handleSignupShow={handleSignupShow}
          />
        </Modal.Body>
      </Modal> */}

      <Modal
        show={showForgot}
        onHide={handleForgotClose}
        centered
        className="jackbit-modal"
      >
        <Modal.Body>
          <ForgotPopup handleForgotClose={handleForgotClose} />
        </Modal.Body>
      </Modal>

      {/* Reset Password popup */}

      <Modal
        show={showReset}
        onHide={handleResetClose}
        centered
        className="jackbit-modal"
      >
        <Modal.Body>
          <ResetPopup
            handleResetClose={handleResetClose}
            resetToken={resetToken}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={transection}
        onHide={handleHideTransection}
        className="jackbit-modal"
        centered
      >
        <Modal.Body>
          <TransectionPopup
            initialTab={transection}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleHideTransection={handleHideTransection}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Layout;
