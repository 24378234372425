import React, { useEffect, useState } from "react";
import AccountLayout from "./index";
import { useMyContext } from "../context";
// import back from "../../assets/images/header/icon/back.svg";
import { getAllTransaction } from "../../utility/Apis";
import { Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import PreArrow from "../common/prevArrow";
import NextArrow from "../common/nextArrow";
import { Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const pageLimit = 10;
function PersonalHistory() {
  const navigate = useNavigate();
  const { windowWidth } = useMyContext();
  const handleGoBack = () => {
    // window.history.back();
    navigate("/view-all/false/lobby/lobby");;
  };

  const { userDetails } = useMyContext();
  let [transaction, setTransaction] = useState([]);
  let [loading, setLoading] = useState(false);
  const [TransactionCount, setTransactionCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const getAllTransactions = async () => {
    try {
      setLoading(true);
      const res = await getAllTransaction({
        userId: userDetails?.id,
        offset: skip,
        limit: pageLimit,
      });
      if (res?.status === 200) {
        setTransaction(res?.history);
        setTransactionCount(res?.count);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  console.log("pageCount", pageCount);
  useEffect(() => {
    setPageCount(Math.ceil(TransactionCount / pageLimit));
  }, [TransactionCount]);
  useEffect(() => {
    getAllTransactions();
    // eslint-disable-next-line
  }, [skip]);
  const handlePageClick = ({ selected }) => {
    setSkip(selected * pageLimit);
    setCurrentPage(selected);
  };
  return (
    <AccountLayout>
      {windowWidth <= 991 && (
        <div className="page-title w-100">
          {/* <div
          className="back"
          // onClick={handleGoBack}
        >
          <img src={back} alt="back" />{" "}
          <span text_key="PAYMENTS__BACK">BACK</span>
        </div> */}
          <h3
            className="page-name"
            text_key="PROFILE_MENU__TRANSACTIONS_HISTORY"
          >
            Transactions History
          </h3>
          <Clear className="close-page" onClick={handleGoBack} />
        </div>
      )}
      <div className="personal-history-page-wrapper overflow-auto history-page-height">
        <div className="content">
          {/* <div className="filter">
            <div className="list-wrapper">
              <ul className="head">
                <li text_key="PROFILE_TRANSACTION__ACTIVITY_TYPE">
                  Activity Type
                </li>
                <li text_key="PROFILE_TRANSACTION__STATUS">Status</li>
                <li text_key="PROFILE_TRANSACTION__FROM_DATE">From Date</li>
                <li text_key="PROFILE_TRANSACTION__TO_DATE">To Date</li>
              </ul>
            </div>
            <div className="list-wrapper">
              <ul className="filters">
                <li>
                  <div
                    className="title"
                    text_key="PROFILE_TRANSACTION__ACTIVITY_TYPE"
                  >
                    Activity Type
                  </div>
                  <select className="operation-type" fdprocessedid="1cuw68">
                    <option
                      value=""
                      text_key="PROFILE_TRANSACTION__ACTIVITY_TYPE"
                      selected="selected"
                      disabled="disabled"
                      hidden=""
                    >
                      Activity Type
                    </option>
                    <option value="" text_key="PROFILE_TRANSACTION__ALL">
                      All
                    </option>
                    <option value="1" text_key="TRANSACTION__DEPOSIT">
                      Deposit
                    </option>

                    <option value="2" text_key="TRANSACTION__WITHDRAW">
                      Withdraw
                    </option>

                    <option value="3" text_key="TRANSACTION__BET">
                      Bet
                    </option>

                    <option value="4" text_key="TRANSACTION__WIN">
                      Win
                    </option>

                    <option value="21" text_key="TRANSACTION__CANCEL_BONUS">
                      Cancel Bonus
                    </option>

                    <option value="22" text_key="TRANSACTION__BONUS_ACTIVATION">
                      Bonus Activation
                    </option>
                  </select>
                </li>
                <li>
                  <div className="title" text_key="PROFILE_TRANSACTION__STATUS">
                    Status
                  </div>
                  <select className="transaction-status" fdprocessedid="f70lq8">
                    <option
                      text_key="PROFILE_TRANSACTION__STATUS"
                      selected="selected"
                      disabled="disabled"
                      hidden=""
                    >
                      Status
                    </option>
                    <option value="" text_key="PROFILE_TRANSACTION__ALL">
                      All
                    </option>
                    <option value="1" text_key="TRANSACTION__SUCCESS">
                      Success
                    </option>

                    <option value="2" text_key="TRANSACTION__PROCESSING">
                      Processing
                    </option>

                    <option value="3" text_key="TRANSACTION__REJECTED">
                      Rejected
                    </option>
                  </select>
                </li>
                <li className="datepicker">
                  <div class="title" text_key="PROFILE_TRANSACTION__FROM_DATE">
                    From Date
                  </div>
                  <input
                    type="date"
                    className="from-date"
                    placeholder_key="PROFILE_TRANSACTION__FROM"
                  />
                  <div className="calendar"></div>
                </li>
                <li className="datepicker">
                  <div
                    className="title"
                    text_key="PROFILE_TRANSACTION__TO_DATE"
                  >
                    To Date
                  </div>
                  <input
                    type="date"
                    className="to-date"
                    placeholder_key="PROFILE_TRANSACTION__TO"
                  />
                  <div className="calendar"></div>
                </li>
                <li>
                  <div className="btn" text_key="PROFILE_TRANSACTION__SUBMIT">
                    Submit
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
          {/* <div className="overflow">
            <div className="loader"></div>
          </div> */}
          <div className="transactions-list">
            <div className="table-wrapper">
              {loading ? (
                <div style={{ zIndex: 99, textAlign: "center" }}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    role="status"
                    aria-hidden="true"
                    variant="light"
                  />
                </div>
              ) : transaction?.length > 0 ? (
                <div className="transaction-table overflow-auto">
                  <table className="bets-table table-responsive h-100">
                    <thead>
                      <tr>
                        <th
                          text_key="PROFILE_TRANSACTION__DATE/TIME"
                          className="text-center custom-date-align"
                        >
                          Date/Time
                        </th>
                        <th
                          style={{ paddingLeft: "16px" }}
                          text_key="PROFILE_TRANSACTION__DATE/TIME"
                          className="text-center"
                        >
                          Activity Type
                        </th>
                        <th
                          text_key="PROFILE_TRANSACTION__TYPE"
                          className="text-center"
                        >
                          Transaction Type
                        </th>
                        {/* <th text_key="PROFILE_TRANSACTION__TYPE">User Id</th> */}
                        <th
                          text_key="PROFILE_TRANSACTION__VENDOR"
                          className="text-center"
                        >
                          Currency
                        </th>
                        <th
                          text_key="PROFILE_TRANSACTION__AMOUNT"
                          className="text-center"
                        >
                          Status
                        </th>
                        {/* <th text_key="PROFILE_TRANSACTION__RESULT">Result</th> */}
                        {/* <th text_key="PROFILE_TRANSACTION__NAME"></th> */}
                        {/* <th></th> */}
                      </tr>
                    </thead>
                    {transaction &&
                      transaction?.map((items) => (
                        <tbody>
                          <tr>
                            <th
                              text_key="PROFILE_TRANSACTION__DATE/TIME"
                              className="text-center"
                            >
                              {items?.updatedAt &&
                                `${new Date(
                                  items?.updatedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })}`}
                              &nbsp;
                              {`${new Date(items?.updatedAt).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                }
                              )}`}
                            </th>
                            <th
                              text_key="PROFILE_TRANSACTION__TYPE"
                              className="text-center"
                            >
                              {items?.transactionType || ""}
                            </th>
                            <th
                              text_key="PROFILE_TRANSACTION__TYPE"
                              className="text-center"
                            >
                              {items?.type}
                            </th>
                            {/* <th text_key="PROFILE_TRANSACTION__VENDOR">
                            {items?.currencyCode || ""}
                          </th> */}
                            <th
                              text_key="PROFILE_TRANSACTION__AMOUNT"
                              className="text-center"
                            >
                              {items?.usdAmount || "0.00"}
                            </th>
                            <th
                              text_key="PROFILE_TRANSACTION__RESULT"
                              className="text-center"
                            >
                              {items?.status || ""}
                            </th>
                            {/* <th></th> */}
                          </tr>
                        </tbody>
                      ))}
                  </table>
                </div>
              ) : (
                <div className="no-data">
                  <div className="material-icons icon">assignment</div>
                  <div
                    className="text"
                    text_key="PROFILE_TRANSACTION__NO_TRANSACTIONS"
                  >
                    Transactions Not Found
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4 position-absolute bottom-20 w-100 ">
            {Number(TransactionCount || 0) > 2 ? (
              <div className="refferal-pagination  transaction-pagination">
                <ReactPaginate
                  previousLabel={<PreArrow />}
                  breakLabel="..."
                  nextLabel={<NextArrow />}
                  // marginPagesDisplayed={-2}
                  // pageRangeDisplayed={3}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous-page"}
                  nextLinkClassName={"next-page"}
                  disabledClassName={"pagination-disabled"}
                  activeClassName={"pagination-active"}
                />
              </div>
            ) : (
              ""
            )}{" "}
          </div>
        </div>
      </div>
    </AccountLayout>
  );
}

export default PersonalHistory;
