import {
  authInstance,
  gameInstance,
  paymentInstance,
  userInstance,
  scriptInstance,
  //userInstanceWithoutToken,
} from "../config/axios";

// ============================authInstance=================================
export const registerUser = async (body) => {
  try {
    const res = await authInstance().post("/register", body);
    return res?.data;
  } catch (error) {
    // console.log(error.response.data)
    return error.response.data;
  }
};
export const loginUser = async (body) => {
  try {
    const res = await authInstance().post("/login", body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const logoutUser = async (body) => {
  try {
    const res = await authInstance().post("/logout", body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const forgotPassword = async (body) => {
  try {
    const res = await authInstance().post("/forgot-password", body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

// ================================ userInstance ==============================
export const getUserDetail = async () => {
  try {
    const res = await userInstance().get("/");
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllScripts = async () => {
  try {
    const res = await scriptInstance().get("/getScripts");
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const UpdateUserProfileData = async (body) => {
  try {
    const res = await userInstance().put(`/updateCredentials`, body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const sendEmailCode = async () => {
  try {
    const res = await userInstance().put(`/getCode`);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const verifyMail = async (body) => {
  try {
    const res = await userInstance().put(`/verifycode`, body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const verifyDocument = async (body) => {
  try {
    const res = await userInstance().post(`/verifyIdentity`, body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

// reset password

export const resetPassword = async (body) => {
  console.log("body??", body);
  try {
    const res = await authInstance().post(
      `/reset-password/?token=${body.token}`,
      { password: body?.password }
    );
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

// payment apis========
export const getTransectonDetails = async (body) => {
  try {
    const res = await paymentInstance().post(`/request-money`, body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const withDrawAmount = async (body) => {
  try {
    const res = await paymentInstance().post(`/withdrawAmount`, body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const checkOut = async (body) => {
  try {
    const res = await userInstance().post(`/checkout`, body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

// games api=========
export const getLandingGames = async () => {
  try {
    const res = await gameInstance().get("/getLandingGames");
    return res?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const startselectedGame = async (body) => {
  try {
    const res = await gameInstance().post("/start-game/start", body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllgamesByCategory = async (body) => {
  try {
    let queryParams = new URLSearchParams(body);

    const url = `/getAllGames/?${queryParams.toString()}`;
    const res = await gameInstance().get(url);
    return res.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getAllFilteredGames = async (body) => {
  try {
    let queryParams = new URLSearchParams({
      page: body.page,
      limit: body.limit,
      search: body.search,
      isMobile: body.isMobile,
      subCategoryId:body?.subCategoryId,
    });

    const res = await gameInstance().get(
      `/getAllFilteredGames/?${queryParams.toString()}`
    );
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getAllProviders = async () => {
  try {
    const res = await gameInstance().get("/getAllProviders");
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getAllTransaction = async (value) => {
  try {
    const res = await userInstance().post("/getTransactionHistory", value);
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const last5Transaction = async () => {
  try {
    const res = await userInstance().post("/last-Transactions");
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const makeUserFavoriteGame = async (gameId) => {
  try {
    const res = await userInstance().post("/make-user-game-favorite",{gameId});
    return res;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getAllSubCategories = async () => {
  try {
    const res = await gameInstance().get("/get-subcategories");
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getBetHistory = async (val) => {
  try {
    const res = await userInstance().post("/getBetHistory", val);
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const allBanners = async (data) => {
  try {
    const res = await gameInstance().get(`/getBannerShow?bannerPage=${data}`);

    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const popupShow = async (data) => {
  try {
    const res = await gameInstance().get(`/getPopup`);
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
