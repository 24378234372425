import React, { useState } from "react";
import play from "../../assets/images/casinogame/gamecard/play.svg";

import { Spinner } from "react-bootstrap";
import { useMyContext } from "../context";
import { useNavigate /* useLocation */ } from "react-router-dom";
import { makeUserFavoriteGame } from "../../utility/Apis";
import { toast } from "react-toastify";
// import notFound from "../../assets/images/casinogame/not-found.png";
// import { height, width } from "@mui/system";
import { Assignment, SearchOff } from "@mui/icons-material";
function GameCard({
  handleGameShow,
  allGamesData: gamesData,
  loading,
  getPageNumber,
  page,
  queriedCount,
  getGame,
  shownGames,
  searchvalue,
  size,
  gameLoader,
}) {
  // const location = useLocation();
  const navigate = useNavigate();
  const { setIsGamePlaying, setFavorites, favorites } = useMyContext();
  const [allGamesShown] = useState(false);

  const handleChangeFav = async (gameId) => {
    try {
      const res = await makeUserFavoriteGame(gameId);
      const {
        data: { message },
      } = res || {};

      if (res?.status === 200) {
        setFavorites((prevFavorites) => {
          // Check if the game is already in favorites
          if (prevFavorites.includes(gameId)) {
            // Remove the game immutably if it exists in favorites
            return prevFavorites.filter((favId) => favId !== gameId);
          } else {
            // Add the game immutably if it doesn't exist in favorites
            return [...prevFavorites, gameId];
          }
        });

        return toast.success(message, {
          toastId: "send-code",
          autoClose: 2000,
        });
      }
    } catch (err) {
      return toast.error("Something went wrong!", {
        toastId: "send-code",
        autoClose: 2000,
      });
    }
  };

  // const toggleFavorite = (identifier) => {
  // setFavorites((prevFavorites) => {
  //   const newFavorites = [...prevFavorites];
  //   if (newFavorites.includes(identifier)) {
  //     const index = newFavorites.indexOf(identifier);
  //     if (index > -1) {
  //       newFavorites.splice(index, 1);
  //     }
  //   } else {
  //     console.log("-------------->", newFavorites);
  //     newFavorites.push(identifier);
  //   }
  //   return newFavorites;
  // });
  // };

  const handleChange = (game) => {
    if (game) navigate(`/game/${game?.masterCasinoGameId}`);
    // navigate(
    //   location?.pathname + "?game=" + game?.name?.replace(/\s+/g, "-")
    // );
  };

  return (
    <>
      <div className="games-wrapper">
        {gameLoader && (
          <div
            style={{
              zIndex: 99,
              // textAlign: "center",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "20px",
              width: "100%",
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              variant="light"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        )}
        {!gameLoader ? (
          gamesData.length > 0 ? (
            <ul className={`games`}>
              {gamesData?.map((game, index) => (
                <li
                  key={game.masterCasinoGameId}
                  gameid={game?.masterCasinoGameId}
                  category={game?.masterGameSubCategoryId}
                >
                  <div className="bg" onClick={() => getGame(game)}>
                    <img src={game?.thumbnailUrl} alt="category-games" />

                    <div className="slot-hover">
                      <span onClick={handleGameShow}>
                        <img
                          onClick={() => {
                            handleChange(game);
                            setIsGamePlaying(true);
                          }}
                          className="play"
                          src={play}
                          alt="play icon"
                        />
                      </span>
                      <div className="star-wrapper">
                        <span
                          className="material-icons"
                          onClick={() =>
                            handleChangeFav(game?.masterCasinoGameId)
                          }
                        >
                          {favorites?.length > 0 &&
                          favorites?.includes(game?.masterCasinoGameId)
                            ? "favorite"
                            : "favorite_border"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <h6 className="pt-2 text-center">
                    {game?.name?.length > 30
                      ? game?.name?.substring(0, 30) + "..."
                      : game?.name}
                  </h6>
                </li>
              ))}
            </ul>
          ) : searchvalue ? (
            <div className="no-result-section">
              <div className="text-center">
                <SearchOff className="mb-3" />
                <h5>No Results Found</h5>
                <p>Check the spelling or try another Search.</p>
              </div>
            </div>
          ) : (
            <div className="no-result-section">
              <div className="text-center">
                <Assignment className="mb-3" />
                <h5>No Games</h5>
              </div>
            </div>
          )
        ) : null}

        {loading ? (
          <div
            style={{
              zIndex: 99,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              variant="light"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        ) : gamesData.length > 0 ? (
          <>
            <div className="loaded">
              <div className="text-wrapper">
                <span id="rendered-count">{gamesData?.length}</span>{" "}
                <span className="of" text_key="CASINO__OF">
                  OF
                </span>{" "}
                <span id="games-count">{queriedCount}</span>
              </div>
              <div className="progress-bar">
                <div
                  className="line"
                  style={{
                    width: (shownGames / size) * 100 + "%",
                  }}
                ></div>
              </div>
            </div>
            {!allGamesShown &&
              gamesData?.length !== queriedCount &&
              gamesData?.length <= queriedCount && (
                <div
                  className="show-more btn solid"
                  onClick={() => {
                    getPageNumber(page + 1);
                  }}
                  text_key="CASINO__LOAD_MORE"
                >
                  Show more
                </div>
              )}
          </>
        ) : null}
      </div>
    </>
  );
}

export default GameCard;

// function NoData() {
//   <div className="no-data">
//     <div className="material-icons icon">assignment</div>
//     <div className="text" text_key="CASINO__NO_GAMES">
//       No games
//     </div>
//   </div>;
// }
