import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./components/home";
import Dashboard from "./components/account/dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import Gift from "./components/account/gift";
import Freespin from "./components/account/freespin";
import Notification from "./components/account/notification";
import PersonalInfo from "./components/account/personalInfo";
import PersonalHistory from "./components/account/personalHistory";
import BetHistory from "./components/account/betHistory";
import Kyc from "./components/account/kyc";
import Casino from "./components/casino/casino";
// import LiveCasino from "./components/casino/liveCasino";
import Promotion from "./components/promotion/promotion";
import AboutUs from "./components/category/aboutus";
import GeneralTerm from "./components/category/generalTerm";
import ResponsibleGame from "./components/category/responsibleGame";
import PrivacyPolicy from "./components/category/privacyPolicy";
import Faq from "./components/category/faq";
import KycPolicy from "./components/category/kyc";
import RefundPolicy from "./components/category/refundPolicy";
import DisputeResolution from "./components/category/disputeResolution";
import Fairness from "./components/category/fairness";
import SelfExclusion from "./components/category/selfExclusion";
// import ScrollToTop from "./components/scrolltop/scrollTop";
import { useMyContext } from "./components/context";
import { getUserDetail, getAllScripts } from "./utility/Apis";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from "./components/privateRoute/privateRoutes";
import { socket } from "./socketConnection";
import { io } from "socket.io-client";
import { gsoftserverpath } from "./config/keys";
import IframeGames from "./components/casino/IframeGame";

// Gsoft socket connection===
const SOCKET_NAMESPACES = {
  GETBALANCE: "/getBalance",
};
export const SOCKET_LISTENERS = {
  BALANCE: "balance",
};
export const socoketFun = () => {
  let gsoftSocket = io(`${gsoftserverpath}${SOCKET_NAMESPACES.GETBALANCE}`, {
    extraHeaders: {
      "access-token": `${localStorage.getItem("token")}`,
    },
  });
  gsoftSocket.on("connect", () => {
    console.log("gsoft socket connected>>>>");
  });
  return gsoftSocket;
};

function App() {
  const navigate = useNavigate();
  const { setUserDetails, userDetails } = useMyContext();
  const token = localStorage.getItem("token") || "";
  const getUserData = async () => {
    try {
      const res = await getUserDetail();
      if (res.data) {
        setUserDetails(res?.data, { token: token });
      } else {
        localStorage.clear();
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (userDetails?.id) {
      socket.emit("joinUser", userDetails?.id?.toString());
    }
  }, [userDetails?.id]);

  const getScripts = async () => {
    try {
      const res = await getAllScripts();
      if (res.data) {
        appendScripts(res.data);
      } else {
        console.warn("Scripts Load Failed.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScripts();
  }, []);

  useEffect(() => {
    socoketFun();
  }, [token]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/view-all/false/lobby/lobby", { replace: true });
    }
  }, [navigate]);

  return (
    <div className="app">
      {/* <ScrollToTop /> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Routes>
        <Route path="/" exact element={<Home />} />

        {/* protects routes  */}
        <Route element={<PrivateRoutes />}>
          <Route path="/account/dashboard" exact element={<Dashboard />} />

          <Route path="/account/gift" exact element={<Gift />} />
          <Route
            path="/account/freespinsbypromo"
            exact
            element={<Freespin />}
          />
          <Route
            path="/account/notifications"
            exact
            element={<Notification />}
          />
          <Route
            path="/account/personalinfo"
            exact
            element={<PersonalInfo />}
          />
          <Route
            path="/account/transactions"
            exact
            element={<PersonalHistory />}
          />
          <Route path="/account/bethistory" exact element={<BetHistory />} />
          <Route path="/account/kyc" exact element={<Kyc />} />
          <Route path="/game/:gameId" exact element={<IframeGames />} />
        </Route>
        {/* <Route path="/casino/casino" exact element={<Casino />} /> */}
        <Route path={`/all-games`} exact element={<Casino />} />
        <Route path={`/view-all/false/:id/:name`} exact element={<Casino />} />
        <Route path="/lobby" exact element={<Casino />} />
        <Route path={`/view-all/false/:id/:name`} exact element={<Casino />} />
        {/* <Route path="/casino/livecasino" exact element={<LiveCasino />} /> */}
        <Route path="/static/promos" exact element={<Promotion />} />
        <Route path="/static/aboutus" exact element={<AboutUs />} />
        <Route path="/static/generalterms" exact element={<GeneralTerm />} />
        <Route
          path="/static/responsiblegaming"
          exact
          element={<ResponsibleGame />}
        />
        <Route path="/static/privacypolicy" exact element={<PrivacyPolicy />} />
        <Route path="/static/faq" exact element={<Faq />} />
        <Route path="/static/kycpolicy" exact element={<KycPolicy />} />
        <Route path="/static/refoundpolicy" exact element={<RefundPolicy />} />
        <Route
          path="/static/disputeresolution"
          exact
          element={<DisputeResolution />}
        />
        <Route path="/static/fairnessrng" exact element={<Fairness />} />
        <Route path="/static/selfexclusion" exact element={<SelfExclusion />} />
      </Routes>
    </div>
  );
}

export default App;

const decodeScriptContent = (encodedScript) => {
  return encodedScript
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&");
};

const appendScripts = (scripts) => {
  scripts.forEach((scriptString, index) => {
    const temp = document.createElement("div");
    temp.innerHTML = decodeScriptContent(scriptString.scriptContent);
    const scriptElements = temp.getElementsByTagName("script");

    Array.from(scriptElements).forEach((script) => {
      const newScript = document.createElement("script");

      Array.from(script.attributes).forEach((attr) => {
        newScript.setAttribute(attr.name, attr.value);
      });

      if (script.innerHTML) {
        newScript.innerHTML = script.innerHTML;
      }
      if (scriptString.scriptName === "google_analytics_script") {
        document.head.appendChild(newScript);
      } else {
        document.body.appendChild(newScript);
      }
    });
  });
};
